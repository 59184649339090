$color-1: #ffffff;
$color-2: #000000;
$color-2-tint: #000000cc;
$color-3: #eeeeee;
$color-3-tint: #cccccc;
$color-3-dark-tint: #999999;
/**
$color-4: #A14D4D;
$color-4-tint: #f7e8e8;
$color-4-dark: #663434;
**/
$color-4: #438ad1;
$color-4-tint: #7ab2eb;
$color-4-dark: #1976d2;
$color-5: #c1c1c1;
$color-5-tint: #c1c1c111;
