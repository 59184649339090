@import url("https://fonts.googleapis.com/css?family=Material+Icons|Paytone+One|Roboto:300italic,400,300,700,800|Open+Sans:400,800");
[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "Material Icons" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

.icon-user:before {
	content: "\e851";
}
.icon-filter:before {
	content: "\ef4f";
}
.icon-sort:before {
	content: "\e164";
}
.icon-enter:before {
	content: "\ea77";
}
.icon-exit:before {
	content: "\e879";
}
.icon-login:before {
	content: "\ea77";
}
.icon-settings:before {
	content: "\e8b8";
}
.icon-plus-plain:before {
	content: "\e145";
}
.icon-plus:before {
	content: "\e146";
}
.icon-movie:before {
	content: "\e54d";
}
.icon-tv-season:before,
.icon-tv-episode:before,
.icon-tv-show:before,
.icon-tv:before {
	content: "\e333";
}
.icon-album:before {
	content: "\e019";
}
.icon-block:before {
	content: "\e14b";
}
.icon-video-game:before,
.icon-videogame:before {
	content: "\e338";
}
.icon-podcast-episode:before,
.icon-podcast:before {
	content: "\e029";
}
.icon-book:before {
	content: "\ea19";
}
.icon-close:before {
	content: "\e5cd";
}
.icon-star:before {
	content: "\e838";
}
.icon-star-empty:before {
	content: "\e83a";
}
.icon-globe:before {
	content: "\e80b";
}
.icon-comment:before {
	content: "\e253";
}
.icon-pencil:before {
	content: "\e150";
}
.icon-cancel-circle:before {
	content: "\e888";
}
.icon-bookmark:before {
	content: "\e866";
}
.icon-visibility:before {
	content: "\e8f4";
}
.icon-home:before {
	content: "\e88a";
}
.icon-filter:before {
	content: "\e152";
}
.icon-sort:before {
	content: "\e164";
}
.icon-notifications:before {
	content: "\e7f4";
}
.icon-profile:before {
	content: "\e7fd";
}
.icon-search:before {
	content: "\e8b6";
}
.icon-replies:before {
	content: "\e8af";
}
.icon-error:before {
	content: "\e000";
}
.icon-quote:before {
	content: "\e244";
}
.icon-follow:before {
	content: "\e7ff";
}
.icon-unfollow:before {
	content: "\e7fd";
}
.icon-settings:before {
	content: "\e8b8";
}
.icon-mutual:before {
	content: "\ea18";
}
.icon-arrow-right:before {
	content: "\f1df";
}
.icon-arrow-left:before {
	content: "\f1e6";
}
.icon-love:before {
	content: "\e87d";
}
.icon-recommend:before {
	//content: "\e9d2";
	content: "\ea70";
}
.icon-list:before {
	content: "\e896";
}
.icon-drag:before {
	content: "\e945";
}
.icon-save:before {
	content: "\e161";
}
.icon-consumie:before {
	content: "\e660";
}
