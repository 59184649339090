@import './variables.scss';

html {
	height: 100%;
	overflow: hidden;
}
body {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: auto;
}

body,
input,
select,
textarea {
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-weight: 300;
	background: $color-1;
}
.link,
a {
	color: $color-2;
	text-decoration: none;
	font-weight: 400;
	cursor: pointer;
}
a.plain {
	font-weight: 300;
}
.link:hover,
a:hover {
	color: $color-4-dark;
}
.bold {
	font-weight: 700;
}
.toggle {
	font-size: 0.8rem;
	text-transform: lowercase;
}
.toggle:before {
	content: '[';
}
.toggle:after {
	content: ']';
}
.gone {
	display: none !important;
}
.autocomplete-img {
	border: 1px solid $color-2;
}
.autocomplete-fallback {
	border: 1px solid $color-2;
	width: 40px;
	height: 40px;
	padding-top: 5px;
	line-height: 40px;
	font-size: 30px;
	margin-right: 1rem;
	background: $color-3-tint;
	background: linear-gradient(
		180deg,
		rgba(204, 204, 204, 1) 0%,
		rgba(153, 153, 153, 1) 100%
	);
	color: #333;
	text-align: center;
}
.like-button {
}
.like-button-comments {
	margin-right: 0.5rem;
	vertical-align: middle;
}
.item .like-button-comments,
.item .like-button-icon {
	margin-right: 0;
}
.like-button-likes {
	vertical-align: middle;
}
.like-button-icon {
	margin-right: 0.25rem;
	vertical-align: middle;
}
.like-button-icon-clickable {
	cursor: pointer;
}
.like-button-icon-clickable:hover,
.like-button-icon-clickable.checked:hover {
	color: $color-4-tint;
}
.like-button-icon-clickable.checked {
	color: $color-4;
}
.like-button-icon-clickable.inert {
	cursor: auto;
}
.like-button-icon-clickable.inert:hover {
	color: $color-2;
}
.like-button-icon-clickable.inert.checked:hover {
	color: $color-4;
}

.like-button-text {
	vertical-align: middle;
}
#root {
	overflow: hidden;
	height: 100%;
}
#root > div {
	display: grid;
	grid-template-columns: 12rem 1fr;
	grid-template-rows: auto 1fr;
	height: 100%;
}
.left-nav {
	grid-column: 1;
	grid-row: 1/3;
	background: $color-3;
	border-right: 1px solid $color-5;
	z-index: 1001;
	overflow-y: auto;
}
.section-nav {
	grid-column: 2;
	grid-row: 1;
	height: 2rem;
	background: $color-1;
	border-bottom: 1px solid $color-5;
	z-index: 19;
	padding: 0.25rem 1.5rem 0.25rem 1.5rem;
	display: grid;
	grid-template-columns: 1fr auto;
}
.main {
	grid-column: 2;
	grid-row: 2;
	overflow: auto;
	padding: 1rem;
}
.consumie-logo > div {
	height: 1.6rem;
	width: 1.6rem;
	margin: 0.2rem;
	display: grid;
	grid-gap: 3px;
	grid-template-rows: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
}
.consumie-logo > div > div {
	background: $color-2;
}
.consumie-logo > div > div:nth-child(1) {
	grid-row: 1/3;
	grid-column: 1;
}
.consumie-logo > div > div:nth-child(2) {
	grid-row: 1;
	grid-column: 2;
}
.consumie-logo > div > div:nth-child(3) {
	grid-row: 2;
	grid-column: 2;
}
.left-nav > div {
	margin-top: 1rem;
	margin-left: 1rem;
	color: $color-2;
	cursor: pointer;
	position: relative;
}
.left-nav-control .nav-alert,
.left-nav .nav-alert {
	position: absolute;
	font-size: 1rem;
	background: $color-4-dark;
	left: 2rem;
	top: 0.5rem;
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 2rem;
}
.left-nav > div:hover .nav-alert {
	background: $color-4-tint;
}
.left-nav > div.inactive {
	cursor: default;
	color: $color-3-tint;
}
.left-nav > div:hover font {
	background: $color-4-dark;
	color: $color-1;
	transition: background 0.2s, color 0.2s;
}
.left-nav > div.inactive:hover font {
	background: $color-3;
	color: $color-3-tint;
}
.left-nav > div:hover font > div > div {
	background: $color-1;
	transition: background 0.2s;
}
.left-nav > div:hover > span {
	color: $color-4-dark;
}
.left-nav > div.inactive:hover > span {
	color: $color-3-tint;
}
.left-nav > div > font {
	font-size: 2rem;
	border-radius: 0.25rem;
	padding: 0.5rem;
	display: inline-block;
	vertical-align: middle;
	margin-right: 0.25rem;
}
.left-nav > font,
.left-nav > a > font {
	font-size: 4rem;
	margin: 3rem auto 0 auto;
	display: block;
	text-align: center;
	border-radius: 0.25rem;
	width: 4rem;
	height: 4rem;
	color: $color-1;
	background: $color-2;
	cursor: pointer;
}
.left-nav > font.inactive {
	background: $color-3-tint;
	color: $color-3;
	cursor: default;
}
.left-nav > a:hover > font {
	background: $color-4-dark;
}
.left-nav > div > span {
	font-weight: 600;
}
.logo-text {
	font-family: 'Paytone One', sans-serif;
	text-transform: uppercase;
	font-size: 1.25rem;
	vertical-align: middle;
}
.loader {
	width: 10px;
	height: 10px;
	background: $color-1;
	display: inline-block;
	vertical-align: top;
}
.controls > div {
	display: inline-block;
	margin-left: 1rem;
	cursor: pointer;
}
.controls > div.inactive {
	cursor: default;
}
.controls > div.inactive:hover {
	color: $color-2;
}
.controls-dim {
	color: $color-5;
}
.controls > a {
	position: relative;
}
.controls .nav-alert {
	position: absolute;
	font-size: 1rem;
	background: $color-4-dark;
	left: 1.5rem;
	top: 0.1rem;
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 2rem;
}
.controls font,
.controls i {
	font-size: 1.5rem;
	line-height: 2rem;
	vertical-align: middle;
}
.controls > div span {
	line-height: 2rem;
	font-size: 0.85rem;
	padding-left: 0.25rem;
	vertical-align: middle;
}
.controls > div:hover {
	color: $color-4-dark;
}
.controls.inert > div:hover {
	color: inherit;
	cursor: inherit;
}
.section-nav .title {
	grid-column: 1;
	grid-row: 1;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 2rem;
	font-size: 1.25rem;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
}
.section-nav .title a {
	text-decoration: none;
	color: $color-2;
}
.section-nav .title a:hover {
	color: $color-4-dark;
}
.section-nav .title:hover {
	color: $color-2;
}
.section-nav .controls {
	grid-column: 2;
	grid-row: 1;
	font-size: 1rem;
	line-height: 2rem;
}

.about {
	grid-column: 1/3;
	grid-row: 1/3;
	overflow: auto;
}
.about-header {
	margin: 1rem 1rem 0rem 1rem;
	font-weight: 400;
	font-size: 1.2rem;
	line-height: 2.2rem;
}
.about-header-logo {
	display: grid;
	grid-gap: 0.1rem;
	grid-template-columns: 1rem 1rem;
	grid-template-rows: 1rem 1rem;
	float: left;
	margin-right: 1rem;
}
.about-header-logo > div {
	background: $color-2;
}
.about-header-logo > div:nth-child(1) {
	grid-column: 1;
	grid-row: 1/3;
}
.about-header-logo > div:nth-child(2) {
	grid-column: 2;
	grid-row: 1;
}
.about-header-logo > div:nth-child(3) {
	grid-column: 2;
	grid-row: 2;
}

.empty-feed {
	margin-left: 1rem;
}
.empty-feed-header {
	font-size: 1.25rem;
	font-weight: 400;
	margin-bottom: 1rem;
}
.steps li {
	list-style-type: none;
	line-height: 2rem;
	margin-bottom: 1rem;
}
.steps li font {
	font-size: 2rem;
	vertical-align: middle;
	margin-right: 0.5rem;
}
.steps li:first-child font {
	background: $color-2;
	color: $color-1;
	border-radius: 0.25rem;
}
.steps li:first-child font:hover {
	background: $color-4-dark;
}
.feed-controls-settings {
	margin-right: 1rem;
}
.user-content > .smalls,
.user-content > .box-wrapper,
.home > .smalls,
.home > .box-wrapper {
	display: inline-block;
	width: 400px;
	text-align: left;
	vertical-align: top;
}
.box-wrapper > .content {
	margin: 10px;
}
.smalls > .box-wrapper:first-child > .content {
	margin-bottom: 20px;
}
.image-container {
	position: relative;
}
.image-container .final,
.image-container .placeholder {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
}
.image-container .final {
	opacity: 0;
	transition: opacity 0.25s;
}
.image-container .final.loaded {
	opacity: 1;
}
.item {
	height: 542px;
	display: grid;
	box-sizing: border-box;
	grid-template-columns: 70px minmax(0, 1fr);
	grid-template-rows: 1fr auto auto auto;
	max-width: 100%;
	border: 1px solid $color-2;
	background: $color-2;
	overflow: hidden;
}
.item .item-image {
	grid-column: 1/3;
	grid-row: 1/4;
	opacity: 1;
	transition: all 0.2s ease-in-out;
	position: relative;
}
.item:hover .item-image {
	//opacity: 0.95;
	//transform: scale(1.01);
}
.item .item-image span {
	line-height: 542px;
	font-size: 200px;
	color: #333;
	font-size: 150px;
	text-align: center;
	display: inline-block;
	width: 100%;
	background: $color-3-tint;
	background: linear-gradient(
		180deg,
		rgba(204, 204, 204, 1) 0%,
		rgba(153, 153, 153, 1) 100%
	);
}
.smalls .item-image span {
	line-height: 260px;
}
.smalls .item {
	height: 260px;
}
.item .viewer {
	grid-row: 2/5;
	grid-column: 1;
	padding: 10px 10px 0px 10px;
	background: $color-2-tint;
	z-index: 3;
}
.item .viewer a {
	// width: 50px;
	// height: 60px;
	// display: block;
}
.item .title-subtitle {
	grid-row: 2;
	grid-column: 2;
	background: $color-2-tint;
	z-index: 3;
	padding: 10px 10px 0px 0px;
	color: $color-1;
}
.item .title {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	line-height: 1.25rem;
	font-family: 'Open Sans', sans-serif;
}
.item .item-comment {
	border-top: 1px solid $color-5;
	grid-column: 1/3;
	grid-row: 4;
	background: $color-2-tint;
	z-index: 3;
	color: $color-1;
	font-style: italic;
	padding: 0.5rem 10px 10px 10px;
}
.item a {
	color: $color-1;
	text-decoration: none;
}
.viewer span {
	height: 50px;
	width: 50px;
	line-height: 50px;
	text-align: center;
	background: $color-2;
	color: $color-1;
	display: inline-block;
	font-size: 30px;
}
.box-wrapper .viewer span {
	height: 60px;
	line-height: 60px;
}
.item .title a {
	font-weight: 800;
	font-size: 1.25rem;
	text-transform: uppercase;
	vertical-align: bottom;
}
.item .title i {
	font-size: 1.25rem;
	vertical-align: bottom;
	font-weight: 100;
}
.item .subtitle {
	font-style: italic;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.item-info-bottom {
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: auto 1fr auto auto;
	width: 100%;
	box-sizing: border-box;
	grid-column: 2;
	grid-row: 3;
	padding-right: 10px;
	background: $color-2-tint;
	z-index: 3;
	color: $color-1;
	padding-bottom: 5px;
}
.item .username {
	font-weight: 700;
	grid-row: 1;
	grid-column: 1;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-right: 0.25rem;
	align-self: end;
	text-transform: lowercase;
}
.item .date {
	grid-row: 1;
	grid-column: 2;
	white-space: nowrap;
	font-size: 0.75rem;
	text-transform: uppercase;
	font-style: italic;
	align-self: end;
	margin-right: 0.5rem;
	margin-bottom: 0.05rem;
}
.item .rating {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-family: 'Paytone One', sans-serif;
	font-size: 0.85rem;
	margin-right: 0.5rem;
	grid-row: 1;
	grid-column: 3;
	text-align: center;
	user-select: none;
	align-self: end;
	margin-bottom: 0.075rem;
}
.item .rating-numerator:after {
	content: '/';
}
.item .buttons {
	font-size: 0.9rem;
	color: $color-1;
	grid-row: 1;
	grid-column: 4;
	white-space: nowrap;
	user-select: none;
	align-self: end;
	margin-bottom: -0.1rem;
}
.item .toggle {
	font-style: normal;
}
.controls font.checked {
	color: $color-4;
}
.item a:hover {
	color: $color-4-tint;
	text-decoration: none;
	cursor: pointer;
}
.item .buttons span {
	color: $color-1;
	margin-left: 0.25rem;
}
.item .buttons a {
	outline: none;
}
.item .buttons i,
.item .buttons span span {
	line-height: 1rem;
	vertical-align: top;
}

// BOOKMARKS

.home.bookmarks > .smalls,
.home.bookmarks > .box-wrapper {
	width: 272px;
}
.home.bookmarks .smalls .item {
	height: 171px;
}
.home.bookmarks .item {
	height: 362px;
	grid-template-columns: 0px minmax(0, 1fr);
}
.home.bookmarks .item .item-info-bottom,
.home.bookmarks .item .title-subtitle {
	padding-left: 10px;
}

// CONTENT

.content .content-top {
	display: grid;
	grid-template-columns: 300px 1fr auto;
	grid-template-rows: auto 1fr;
	grid-column-gap: 2rem;
	padding: 0.5rem 0.5rem 0px 0.5rem;
}
.content .content-left {
	display: grid;
	grid-row-gap: 1rem;
	grid-column: 1;
	grid-row: 1/3;
	align-self: start;
}
.content .content-paren {
	grid-column: 2;
	grid-row: 1;
	margin-bottom: 0.5rem;
}
.content .content-right {
	display: grid;
	align-self: start;
	grid-column: 2;
	grid-row: 2;
	grid-row-gap: 1rem;
	max-width: 600px;
}
.content .content-left .img {
}
.content .content-left .controls {
	text-align: center;
}
.content .content-left .controls > div:first-child {
	margin-left: 0px;
}

.content .content-top .img img,
.content .content-top .img span {
	border: 1px solid $color-2;
	//box-shadow: 2px 2px 5px #000;
	overflow: hidden;
	//border-radius: 1em 1em 0px 0px;
}
.content .content-top .img span {
	background: $color-3-tint;
	background: linear-gradient(
		180deg,
		$color-3-tint 0%,
		$color-3-dark-tint 100%
	);
	width: 300px;
	border: 1px solid $color-2;
	display: block;
	text-align: center;
}
.content .content-top .img span:before {
	font-size: 150px;
}
.content .content-top .img span.size-0 {
	line-height: 206px;
	height: 206px;
}
.content .content-top .img span.size-1 {
	line-height: 431px;
	height: 431px;
}
.content .content-top .img img {
	border: 1px solid $color-2;
}
.content .content-top .title {
	font-weight: 800;
	text-transform: uppercase;
	font-size: 1.5rem;
}
.content .content-top .paren {
	text-transform: uppercase;
	font-size: 0.8rem;
	border-bottom: 1px solid $color-5;
	padding-bottom: 0.1rem;
}
.content .content-top .paren div {
	display: inline-block;
}
.content .content-top ol {
	margin: 0px;
	padding-top: 0px;
}
.content .content-top .tagline {
	font-style: italic;
}
.content .content-top .body {
}
.content .content-top.content-top-podcast .body,
.content .content-top.content-top-tv-episode .body,
.content .content-top.content-top-tv .body,
.content .content-top.content-top-book .body {
}
.content .content-top .list {
}
.content .content-top .streaming-options {
	font-size: 0.75rem;
	text-transform: uppercase;
	text-align: center;
}
.content .content-top .streaming-options .streaming-title {
	border-bottom: 1px solid $color-5;
	padding-bottom: 0.1rem;
	margin-bottom: 0.75rem;
}
.content .content-top .streaming-options img {
	width: auto;
	height: 50px;
	margin-right: 1rem;
}
.content .content-attribution {
	grid-column: 1/3;
	grid-row: 3;
	font-size: 0.75rem;
	text-transform: uppercase;
	text-align: center;
	margin-top: 2rem;
}
.content .content-top .activity-bar {
	margin-bottom: 0.25rem;
}
.content .content-top .reviews,
.content .content-top .activity {
}
.discover-group-grid-wrapper {
}
.discover-group-grid-wrapper .pagination {
}
.discover-group-grid,
.content .similar-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-column-gap: 1rem;
}
.discover-group-grid .row,
.content .similar-grid .row {
	text-align: center;
}
.discover-group-grid-wrapper .viewer {
	height: 56px;
}
.discover-group-grid img,
.content .similar-grid img {
	border: 1px solid $color-2;
}
.discover-group-grid span,
.content .similar-grid span {
	width: 50px;
	display: inline-block;
	text-align: center;
	border: 1px solid $color-2;
}
.discover-group-grid span.size-2,
.content .similar-grid span.size-2 {
	height: 50px;
	line-height: 50px;
	font-size: 35px;
}
.discover-group-grid span.size-1,
.content .similar-grid span.size-1 {
	height: 72px;
	line-height: 72px;
	font-size: 35px;
}
.discover-group-grid span.size-0,
.content .similar-grid span.size-0 {
	height: 34px;
	line-height: 34px;
	font-size: 25px;
}
.discover-group-grid .action a,
.content .similar-grid .action a {
	font-size: 0.9rem;
}
.discover-group-grid .parenthetical,
.content .similar-grid .parenthetical {
	font-size: 0.85rem;
	font-weight: 100;
	font-style: italic;
}
.content .content-top .reviews .review {
	white-space: pre-line;
}
.content .content-top .like-button {
	margin-top: 0.15rem;
}
.content .content-top .activity .like-button {
	display: block;
}
.content .content-top .pagination {
	margin-top: 0.5rem;
	text-align: center;
}
.content .content-top .pagination > div {
	display: inline-block;
}
.content .content-bottom {
	display: grid;
	grid-template-columns: 1fr 2rem 1fr;
	grid-template-rows: auto;
	margin: 1rem 0.5rem 0px 0.5rem;
}
.content .content-bottom > div:last-child {
	grid-column: 3;
	grid-row: 1;
}
.content .column-header {
	text-transform: uppercase;
	font-size: 0.8rem;
	border-bottom: 1px solid $color-5;
	padding-bottom: 0.1rem;
	margin-bottom: 0.75rem;
}
.content .reviews .row,
.content .activity .row {
	display: grid;
	grid-template-columns: 52px 1rem 1fr;
	grid-template-rows: auto;
	margin-top: 1rem;
	border-bottom: 1px solid $color-5;
	padding-bottom: 1rem;
}
.content .reviews .row:last-child,
.content .activity .row:last-child {
	border-bottom: 0px;
}
.reply-body .content-rating-top,
.content .content-rating {
	margin-right: 0.5rem;
	font-family: 'Paytone One', sans-serif;
	font-size: 0.85rem;
}
.content .reviews .comment font,
.content .activity .comment font {
	margin-right: 0.25rem;
	vertical-align: middle;
}
.content .reviews .review .review-more {
	margin-right: 0px;
}
.inline-likes a.comment {
	color: $color-2;
}
.content .row .viewer {
	grid-column: 1;
	grid-row: 1/4;
	border: 1px solid $color-2;
	height: 50px;
}
.content .row .action {
	grid-column: 3;
	grid-row: 2;
}
.content .row .date {
	grid-column: 3;
	grid-row: 1;
	text-transform: uppercase;
	font-size: 0.8rem;
}
.content .row .review {
	grid-column: 3;
	grid-row: 3;
}
.content .row .comments {
	grid-column: 3;
	grid-row: 4;
}
.content .more {
	margin-bottom: 1rem;
	text-transform: uppercase;
	font-size: 0.8rem;
	text-align: right;
	float: right;
}
.content .more:after {
	content: ' »';
}
.content-top .list-item {
	display: inline;
	border-bottom: 0px;
}
.content-top .list-item:after {
	content: ' | ';
}
.content-top .list-item:last-child:after {
	content: none;
}

.inline-likes {
	display: inline-block;
}
.inline-likes > span {
	vertical-align: middle;
	font-size: 1.05rem;
}
.inline-likes a {
	vertical-align: middle;
	margin-right: 0.25rem;
	color: $color-4-tint;
}
.inline-likes a.checked {
	color: $color-4;
}
.inline-likes a:hover {
	color: $color-4-dark;
}
.inline-likes a.inert:hover {
	color: $color-4-tint !important;
	cursor: auto;
}
// splash page
.splash {
	background-color: $color-1;
	grid-column: 1/3;
	display: grid;
	grid-template-columns: 4fr 6fr;
	grid-template-rows: auto;
	grid-column-gap: 1rem;
	position: fixed;
	top: 0px;
	bottom: 0px;
}
.splash-small {
	font-size: 0.9rem;
	margin-top: 0.5rem;
}
.splash-content {
	grid-column: 1;
	z-index: 5;
	color: $color-2;
	margin: 1rem;
	font-size: 1.1rem;
	font-weight: 400;
	align-self: end;
	padding-bottom: 4rem;
}
.splash-content > div {
	margin-bottom: 1rem;
}
// .splash > div.quilt {
// 	margin-top: -2rem;
// 	margin-bottom: -1rem;
// 	overflow: hidden;
// 	opacity: 0;
// 	transition: opacity 0.25s linear;
// }
// .splash > div.quilt.visible {
// 	opacity: 1;
// }
.splash > div.quilt {
	overflow: hidden;
	opacity: 1;
	transition: opacity 0.25s linear;
	display: flex;
	column-gap: 3px;
	margin-top: -1rem;
	margin-bottom: -1rem;
	margin-right: -1rem;
}
.quilt_column {
	display: flex;
	flex-direction: column;
	width: 20%;
}
.quilt_column img {
	width: 100%;
}
.splash > div.quiltt {
	grid-column: 2;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: auto auto auto;
	grid-column-gap: 2px;
	grid-row-gap: 2px;
}
.splash > div.quiltt > div {
	background-size: cover;
	background-position: center;
}
.splash .splash-header {
	margin-bottom: 1rem;
	margin-left: -0.5rem;
	display: block;
}
.splash .splash-header > font {
	font-size: 4.5rem;
}
.splash .consumie-logo > div {
	float: left;
	height: 3.2rem;
	width: 3.2rem;
}
.splash .consumie-logo > div > div {
	background: $color-2;
}
.splash .splash-header > span {
	font-size: 1.5rem;
	font-weight: 800;
	margin-left: 1rem;
}
.splash a {
	// color: $color-1;
}
.splash a:hover {
	opacity: 0.9;
}
.splash a.buttony {
	background: $color-1;
	color: $color-2;
	border: 1px solid $color-4;
	border-radius: 0.25rem;
	padding: 1rem;
	margin-top: 1rem;
	display: block;
	user-select: none;
	margin-bottom: 1rem;
	margin-top: 1.5rem;
}
.splash a.buttony:hover {
	background: $color-4-tint;
}
// add Screen

.modal-slider-wrapper {
	overflow-x: hidden;
}
.modal-slider {
	white-space: nowrap;
	transition: transform 0.2s linear;
}
.modal-slider.no-slide {
	transition: none;
}
.modal-slider.no-slide .side-by-side:nth-child(1),
.modal-slider.no-slide .side-by-side:nth-child(2) {
	visibility: hidden;
}
.modal-slider.screen-1 {
	transform: translate(0px, 0px);
}
.modal-slider.screen-2 {
	transform: translate(-100%, 0px);
}
.modal-slider.screen-3 {
	transform: translate(-200%, 0px);
}
.side-by-side {
	width: 100%;
	overflow: hidden;
	display: inline-block;
	height: 21.5rem;
	white-space: normal;
	position: relative;
}
.side-by-side .type-select {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 6.5rem 6.5rem 6.5rem;
	grid-gap: 1rem;
}
.side-by-side .type {
	text-align: center;
	background: $color-2;
	border-radius: 0.2rem;
	color: $color-1;
	cursor: pointer;
	padding-top: 1.25rem;
}
.modal-slider-controls > div {
	cursor: pointer;
	color: $color-2;
}
.modal-slider-controls > div:hover {
	color: $color-4-dark;
}
.modal-slider-controls > .back {
	float: left;
}
.modal-slider-controls > .back:before {
	content: '« ';
}
.modal-slider-controls > .next {
	float: right;
	cursor: pointer;
}
.modal-slider-controls > .next:after {
	content: ' »';
}
.modal-content .rating {
	border: 0.1rem solid $color-2;
	border-radius: 0.25rem;
	box-sizing: border-box;
}
.modal-content .rating .clear-icon {
	position: absolute;
	font-size: 1.5rem;
	top: 1.9rem;
	left: 1rem;
	cursor: pointer;
}
.modal-content .rating .clear-icon:hover {
	color: $color-4;
}
.modal-content .rating .full-stars,
.modal-content .rating .empty-stars {
	position: absolute;
	font-size: 2rem;
	top: 1.5rem;
	left: 3rem;
}
.modal-content .input-pair {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 1rem;
}
.modal-content .rating .full-stars {
	overflow: hidden;
	color: $color-4;
}
.modal-content .rating .rating-value {
	position: absolute;
	left: 24rem;
	top: 1rem;
	font-size: 2rem;
	font-weight: 500;
}
.modal-content .rating .floating-label {
	top: 0.5rem;
	left: 1rem;
	font-size: 11px;
	opacity: 1;
	color: $color-4;
	position: absolute;
	pointer-events: none;
}
.react-datepicker-wrapper {
	display: block;
}
.type-selected-book .type-book,
.type-selected-tv .type-tv,
.type-selected-videogame .type-videogame,
.type-selected-podcast .type-podcast,
.type-selected-album.type-album,
.type-selected-movie .type-movie {
	background: $color-4-dark;
}
.side-by-side .type:hover {
	background: $color-4;
}
.side-by-side .type font {
	font-size: 2rem;
}
.side-by-side .type span {
	display: block;
	font-size: 1rem;
}
.consumption {
}
.consumption .column-header {
	border-bottom: 0px;
	margin-top: 0.25rem;
}
.column-header-controls {
	float: right;
}
.column-header-controls > span {
	margin-left: 0.5rem;
}
.consumption-review {
	margin-bottom: 1rem;
	border-bottom: 1px solid $color-5;
}
.consumption-reply {
	display: grid;
	grid-template-columns: 52px 1rem auto auto 1fr;
	grid-template-rows: auto 1fr;
	margin-bottom: 1rem;
	border-bottom: 1px solid $color-5;
	padding-left: 1rem;
	padding-bottom: 1rem;
}
.consumption-reply .reply-user {
	grid-column: 1;
	grid-row: 1/3;
	border: 1px solid $color-2;
	height: 50px;
}
.consumption-reply .reply-name {
	grid-column: 3;
	grid-row: 1;
}
.consumption-reply .reply-controls {
	grid-column: 5;
	grid-row: 1;
	margin-left: 1rem;
	text-align: right;
}
.consumption-reply .reply-controls > span {
	display: inline-block;
	vertical-align: middle;
	margin-right: 0.4rem;
	margin-top: 0.2rem;
}
.consumption-reply .reply-controls > span > a {
	display: block;
	line-height: 1.25rem;
}
.consumption-reply .reply-controls > span.inline-likes > a {
	display: inline-block;
}
.consumption-reply .reply-controls a:hover {
	color: $color-4;
}
.consumption-reply .reply-date {
	grid-column: 4;
	grid-row: 1;
	margin-left: 0.5rem;
	text-transform: uppercase;
	color: $color-3-tint;
	align-self: center;
	font-size: 0.75rem;
}
.consumption-reply .reply-body {
	grid-column: 3/6;
	grid-row: 2;
	white-space: pre-line;
}
.consumption-reply .reply-body p {
	margin-top: 0px;
}
.consumption-reply .reply-body p:last-child {
	margin-bottom: 0px;
}
.consumption .consumption-form {
	margin-top: 1.5rem;
}
.consumption .submit {
	margin-top: 2rem;
	margin-bottom: 2rem;
}
.column-header .viewer {
	display: inline-block;
	border: 1px solid $color-5;
	height: 15px;
	vertical-align: text-bottom;
}
.notification {
	margin: 0px 0px 1rem 1rem;
	border-bottom: 1px solid $color-5;
	padding-bottom: 1rem;
	display: grid;
	grid-template-columns: 60px 2rem 1fr;
	grid-template-rows: auto auto 1fr;
}
.notification-user {
	grid-column: 1;
	grid-row: 1/3;
}
.notification-header {
	grid-column: 2/4;
	grid-row: 1;
}
.notification-date {
	grid-column: 2/4;
	grid-row: 2;
	text-transform: uppercase;
	color: $color-3-tint;
	font-size: 0.75rem;
}
.notification-quote {
	grid-column: 2;
	grid-row: 3;
	color: $color-4-tint;
	font-size: 1.5rem;
	align-self: start;
	margin-top: -0.25rem;
}
.notification-body {
	grid-column: 3;
	grid-row: 3;
	align-self: start;
}
.main.user {
	//display: grid;
	//grid-template-columns: 300px 1fr;
	//grid-column-gap: 2rem;
}
.main.home .user-data,
.main.user .user-data {
	display: grid;
	grid-template-columns: auto auto 1fr;
	grid-template-rows: 1fr auto auto;
	grid-column-gap: 2rem;
	grid-row-gap: 0.5rem;
	margin: 0px 0.5rem;
}
.user-data .user-image {
	grid-column: 1;
	grid-row: 1/5;
}
.user-data .user-image img {
	width: 140px;
	height: 140px;
}
.user-data .viewer span {
	width: 140px;
	height: 140px;
	line-height: 140px;
	font-size: 90px;
}
.user-data .member-since {
	grid-column: 2;
	grid-row: 1;
	align-self: end;
}
.user-data .member-since > div {
	display: inline-block;
	border: 0px;
	margin-right: 0.5rem;
	margin-bottom: 0px;
	padding-bottom: 0px;
}
.user-content-stats-button {
	grid-column: 2;
	grid-row: 3;
}
.main.user .user-settings-image img,
.main.user .user-settings-image span,
.main.home .user-data .user-image img,
.main.user .user-data .user-image img {
	border: 1px solid $color-2;
}
.main.home .user-data .user-image img.skeleton,
.main.user .user-data .user-image img.skeleton {
	border: 0px;
}
.user-stats {
	grid-column: 2/4;
	grid-row: 4;
}
.user-stats-pair {
	display: inline-grid;
	grid-template-columns: auto 1fr;
	margin-right: 2rem;
	column-gap: 0.25rem;
}
.user-stats-pair > div {
	align-self: center;
}
.user-stats-type > font {
	display: block;
	font-size: 1.25rem;
}
.user-stats-count {
	font-size: 0.9rem;
	font-weight: 700;
}
.user-content {
	margin: 1.5rem 0.5rem 0 0.5rem;
}
.main.user .box-wrapper > .content {
	margin-left: 0px;
	margin-right: 20px;
}
.user-content-stats {
	grid-column: 2;
	grid-row: 2;
	display: grid;
	grid-template-columns: auto auto auto;
	grid-column-gap: 1rem;
	align-items: center;
}
.user-content-stats > div > span {
	font-weight: 500;
}
.user-content-key {
	text-transform: uppercase;
	font-size: 0.7rem;
	border-bottom: 1px solid $color-5;
	padding-bottom: 0.1rem;
	margin-bottom: 0.75rem;
	grid-column: 1/4;
	margin-top: 0.75rem;
}
.user-content-pair {
	margin-bottom: 1rem;
}
.user-content-value {
}
.relationship {
	border: 1px solid $color-5;
	padding: 0.5rem;
	border-radius: 0.25rem;
	margin: 1rem auto 0px auto;
	text-align: center;
}
.relationship font {
	font-size: 1.5rem;
	vertical-align: middle;
}
.relationship-desc {
	font-size: 0.75rem;
	text-transform: uppercase;
	display: inline;
	vertical-align: middle;
	margin-left: 0.5rem;
}
.settings-updated {
	display: inline-block;
	margin-right: 1rem;
	text-transform: uppercase;
	font-size: 0.75rem;
	color: $color-4;
}
.follow-rows {
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
}
.follow-rows > div {
	align-self: center;
}
.follow-row-image .viewer {
	height: 50px;
}
.follow-row-image .viewer span,
.follow-row-image img {
	border: 1px solid $color-2;
}
.follow-row-name {
	font-weight: 500;
}
.follow-row-link button {
	width: 100%;
}
// discover
.discover {
	margin: 0px 0rem 0px 0rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1rem;
}
.discover-group {
	margin-bottom: 1rem;
}
.discover .pagination {
	text-align: center;
	padding: 1rem;
}
.discover-group-grid-header {
	grid-column: 1/5;
	border-bottom: 1px solid $color-5;
	padding-bottom: 0.5rem;
	text-transform: uppercase;
	font-size: 0.8rem;
	margin-bottom: 0.75rem;
}
.pagination > div {
	display: inline-block;
}
.discover-group-header {
	border-bottom: 1px solid $color-5;
	padding-bottom: 0.5rem;
	text-transform: uppercase;
	font-size: 0.8rem;
	display: none;
}
.discover-group-paper {
	padding: 1rem;
}
.discover-group-search {
	margin-top: 0.5rem;
}
.discover-group-body {
	padding-top: 1rem;
}
.discover-group-body-bg {
	position: absolute !important;
	top: 1.5rem;
	bottom: 0px;
}
.discover-group-body-bg > div {
	height: 100%;
}
.discover-group-body-carousel {
	position: absolute;
	top: 3rem;
	left: 0px;
	right: 0px;
}
.discover-group-body-carousel > div {
	width: 100%;
}
.loading-card {
	line-height: 200px;
	text-align: center;
}
.user-card {
	width: 200px;
	height: 200px;
	position: relative;
}
.content-card-name,
.user-card-name {
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background: $color-2-tint;
	padding: 0.2rem 1rem;
	color: $color-1;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.content-card {
	width: 200px;
	height: 200px;
	overflow: hidden;
	position: relative;
}
.content-card > span {
	font-size: 150px;
	line-height: 200px;
	text-align: center;
	display: block;
}

/** LISTS **/
.lists,
.list-detail,
.edit-list {
	padding: 1rem 1.5rem;
}
.list-item {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: auto auto 1fr;
	grid-column-gap: 1rem;
	border-bottom: 1px solid $color-5;
	margin-bottom: 1rem;
	padding-bottom: 1rem;
}
.list-item-images {
	grid-column: 1;
	grid-row: 1/4;
	width: 202px;
	height: 202px;
	display: grid;
	grid-template-columns: 100px 100px;
	grid-template-rows: 100px 100px;
	grid-column-gap: 2px;
	grid-row-gap: 2px;
}
.list-item-images > a {
	opacity: 1;
	transition: opacity 0.2s;
}
.list-item-images > a:hover {
	opacity: 0.95;
}
.list-item-more a {
	background: $color-2;
	text-align: center;
	line-height: 100px;
	color: $color-1;
	font-weight: 600;
	width: 100%;
	height: 100%;
	display: block;
}
.list-item-more a:hover {
	background: $color-2-tint;
}
.list-item-title {
	grid-column: 2;
	grid-row: 1;
	font-size: 1.1rem;
	margin-bottom: 0.5rem;
}
.list-item-title > a {
	font-weight: 600;
}
.list-item-creator {
	grid-column: 2;
	grid-row: 2;
	display: grid;
	grid-template-columns: auto auto 1fr;
	grid-column-gap: 0rem;
}
.list-item-creator > div {
	align-self: center;
}
.list-item-creator .viewer {
	margin-right: 0.5rem;
}
.list-item-creator .viewer img {
	//border: 1px solid $color-2;
}
.list-item-creator .list-item-creator-date:before {
	content: '. ';
}
.list-item-description {
	grid-column: 2;
	grid-row: 3;
	white-space: pre-line;
}
.list-item-edit {
	display: inline-block;
	margin-left: 1rem;
	font-size: 0.9rem;
}
.list-detail-content {
	border-top: 1px solid $color-5;
	margin-top: 1rem;
}
.content-item-note,
.list-detail-description {
	white-space: pre-line;
}
.list-detail-content .content-item {
	padding: 1rem 0;
	border-bottom: 1px solid $color-5;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: auto auto auto 1fr;
	grid-column-gap: 1rem;
}
.content-item .content-item-image {
	grid-column: 1;
	grid-row: 1/5;
}
.content-item .content-item-image > img,
.content-item .content-item-image > a > img {
	border: 1px solid $color-2-tint;
	opacity: 1;
	transition: opacity 0.2s;
}
.list-detail .list-detail-description {
	margin-top: 0.25rem;
	font-style: italic;
}
.content-item-inline-edit-buttons {
	margin-top: 1rem;
}
.content-item-inline-edit-buttons > button:first-child {
	margin-right: 1rem;
}
.content-item .content-item-image > a:hover > span,
.content-item .content-item-image > a:hover > img {
	opacity: 0.95;
}
.content-item .content-item-name,
.content-item .content-item-name > a {
	font-size: 1.1rem;
	font-weight: 600;
}
.content-item .content-item-parent {
	font-style: italic;
}
.content-item .content-item-parenthetical {
	margin-bottom: 0.5rem;
	text-transform: uppercase;
	font-size: 0.8rem;
}
.content-item-image span {
	width: 100px;
	display: block;
	text-align: center;
	background: $color-2;
	color: $color-1;
	font-size: 60px;
	border: 1px solid $color-2-tint;
	opacity: 1;
	transition: opacity 0.2s;
}
.content-item-image span.size-1 {
	height: 69px;
	line-height: 69px;
}
.content-item-image span.size-1 {
	height: 144px;
	line-height: 144px;
}
.content-item-image span.size-2 {
	height: 100px;
	line-height: 100px;
}
.content-item-edit,
.content-item-delete {
	cursor: pointer;
	font-size: 1.1rem;
}
.edit-list {
	display: grid;
	grid-template-columns: 1fr 1fr 2fr;
	grid-column-gap: 1rem;
	grid-template-rows: min-content min-content;
}
.edit-list .list-name {
	grid-column: 1/3;
	grid-row: 1;
}
.edit-list .list-visibility {
	grid-column: 3;
	grid-row: 1;
}
.edit-list .list-description {
	grid-column: 1/4;
	grid-row: 2;
}
.edit-list .list-add-wrapper-paper {
	grid-column: 1/4;
	grid-row: 3;
	margin-top: 1rem;
	padding: 1rem;
}
.edit-list .list-content {
	grid-column: 1/4;
	grid-row: 4;
}
.edit-list .input {
	margin-top: 1rem;
}
.edit-list .list-add-wrapper {
	display: grid;
	grid-template-columns: 1fr 2fr 2fr;
	grid-column-gap: 1rem;
}
.edit-list .list-add-wrapper .list-add-select {
	grid-column: 1;
	grid-row: 1;
}
#menu- font,
.list-add-select font {
	font-size: 1.5rem;
	vertical-align: bottom;
	margin-right: 0.25rem;
}
.edit-list .list-add-wrapper .list-add-parent {
	grid-column: 2;
	grid-row: 1;
}
.edit-list .list-add-wrapper .list-add-content {
	grid-column: 2/4;
	grid-row: 1;
}
.edit-list .list-add-wrapper .list-add-parent ~ .list-add-content {
	grid-column: 3;
}

.edit-list .list-add-wrapper .list-note {
	grid-column: 1/4;
	grid-row: 2;
}
.edit-list .list-add-wrapper .list-add {
	grid-column: 1/4;
	grid-row: 3;
	text-align: right;
}
.edit-list .list-content-item {
	position: relative;
	margin-bottom: 1rem;
}
.edit-list .list-content-item:hover {
	background: $color-5-tint;
}
.edit-list .list-content-item .content-item {
	display: grid;
	grid-template-columns: auto 1fr auto auto;
	grid-template-rows: auto auto auto 1fr;
	column-gap: 1rem;
	margin: 1rem 1rem 1rem 2.75rem;
}
.edit-list .list-content-item .list-content-item-dragger {
	position: absolute;
	height: 100%;
	display: inline-block;
	width: 1.5rem;
	display: grid;
	border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.edit-list .list-content-item .list-content-item-dragger font {
	font-size: 1.5rem;
	align-self: center;
}

.edit-list .content-item-name {
	grid-column: 2;
	grid-row: 1;
}
.edit-list .content-item-parent {
	grid-column: 2;
	grid-row: 2;
}
.edit-list .content-item-parenthetical {
	grid-column: 2;
	grid-row: 3;
}
.edit-list .content-item-note {
	grid-column: 2;
	grid-row: 4;
}
.edit-list .content-item-delete {
	grid-column: 4;
	grid-row: 1;
}
.edit-list .content-item-edit {
	grid-column: 3;
	grid-row: 1;
}

/* * SEARCH BOX * */
.search-box {
	position: relative;
}
.search-box input {
	padding: 0.85rem 0.5rem 0.1rem 0.5rem;
	font-size: 1rem;
	width: 20rem;
}
.search-box input ~ .clear {
	display: none;
}
.search-box input:disabled ~ .clear {
	display: block;
	position: absolute;
	top: 1.25rem;
	font-size: 1rem;
	color: $color-3;
	background: $color-2;
	border-radius: 5rem;
	width: 1.5rem;
	height: 1.5rem;
	text-align: center;
	line-height: 1.75rem;
	right: 1rem;
	cursor: pointer;
}
.search-box .floating-label {
	position: absolute;
	pointer-events: none;
	left: 0.5rem;
	top: 0.5rem;
	transition: 0.2s ease all;
	font-size: 0.85rem;
}
.search-box .typeahead-enabled input:disabled ~ .floating-label {
	top: 0.5rem;
	left: 1rem;
	font-size: 11px;
	opacity: 1;
	color: $color-2;
}
.search-box input:focus {
}
.search-box input:focus ~ .floating-label,
.search-box input:not(:focus):valid ~ .floating-label {
	top: 0.1rem;
	left: 0.5rem;
	font-size: 0.65rem;
	opacity: 1;
	color: $color-4;
}
.search-box input:disabled ~ .clear {
	display: none;
}
.search-box .typeahead-enabled input:disabled ~ .clear {
	display: block;
}
.search-box .typeahead {
	position: absolute;
	top: 4.25rem;
	left: 0rem;
	right: 0rem;
	max-height: 20rem;
	// border: 1px solid $color-2;
	overflow-y: auto;
	z-index: 10;
	background: $color-1;
	margin-top: 0.25rem;
	box-shadow: 0 3px 9px #00000099;
}
.search-box .typeahead.no-suggestions {
	display: none;
}
.search-box .typeahead-row {
	padding: 0.5rem 1rem;
	cursor: pointer;
	display: grid;
	grid-template-columns: 40px 1rem auto;
	font-size: 1rem;
}
.search-box .typeahead-row-empty {
	grid-template-columns: 0px 1rem auto;
}
.search-box .typeahead-row.selected,
.search-box .typeahead-row:hover {
	background: $color-4-tint;
}
.search-box .typeahead-row > font,
.search-box .typeahead-row img {
	border: 1px solid $color-2;
	grid-column: 1;
}
.search-box .typeahead-row > font {
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 30px;
	background: $color-3-tint;
	background: linear-gradient(
		180deg,
		rgba(204, 204, 204, 1) 0%,
		rgba(153, 153, 153, 1) 100%
	);
	color: #333;
	text-align: center;
}
.search-box .typeahead-row span {
	grid-column: 3;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
// modals
.modal-box .MuiDialog-container > div {
	width: 600px;
}
.modal-backdrop.in {
	background: #8f9cac99;
	opacity: 0.9;
}
.modal-backdrop.in {
	opacity: 0.5;
}
.modal-backdrop.fade {
	opacity: 0;
}
.fade.in {
	opacity: 1;
}
.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1040;
	background-color: #000;
}
.fade {
	opacity: 0;
	transition: opacity 0.15s linear;
}
.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}
.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	outline: 0;
}
.modal.in .modal-dialog {
	transform: translate(0, 0);
}
.modal.fade .modal-dialog {
	transition: transform 0.3s ease-out;
	transform: translate(0, 0%);
}
.modal-dialog {
	position: relative;
	width: auto;
	width: 600px;
	margin: 3rem auto;
}
.modal-content {
	position: relative;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #999;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	outline: 0;
	box-shadow: 0 3px 9px #00000099;
}
.modal-body {
	position: relative;
}
.modal-title {
	font-size: 1.25rem;
	font-weight: 400;
	//margin-right: 1.5rem !important;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 0px !important;
	margin: 1rem 3.5rem 1rem 2rem !important;
	display: relative;
}
.modal-title em {
	font-style: normal;
	font-weight: 600;
}
.modal-body {
	padding: 0rem 2rem 1rem 2rem !important;
}
.modal-close {
	font-size: 1.5rem;
	position: absolute;
	top: 1.05rem;
	right: 2rem;
	cursor: pointer;
}
.modal-close:after {
	content: '\e5cd';
	font-family: 'Material Icons' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}
.modal-contents {
	padding: 0 0rem 0rem 0rem;
}
.modal-contents.fake {
	padding: 0px;
}
.modal-contents .cancel {
	text-align: center;
	margin-top: 0.5rem;
}
.modal-contents .modal-contents .notice {
	margin-bottom: 1rem;
}
.modal-contents .forgot {
	float: right;
	margin-top: 1.75rem;
}
/* *
.modal-contents .input {
	display: block;
	width: 100%;
	position: relative;
	height: 4rem;
	margin-top: 0.5rem;
}
.modal-contents textarea {
	height: 5.5rem;
	resize: none;
	border: 0.1rem solid $color-2;
	border-radius: 0.25rem;
	width: 100%;
	box-sizing: border-box;
	padding: 1.5rem 1rem 0.25rem 1rem;
	font-size: 1rem;
	outline: 0px;
}
.modal-contents select,
.modal-contents input {
	border: 0.1rem solid $color-2;
	border-radius: 0.25rem;
	width: 100%;
	box-sizing: border-box;
	padding: 1.5rem 1rem 0.25rem 0.75rem;
	font-size: 1.3rem;
	height: 4rem;
	outline: 0px;
	background: $color-1;
}
.modal-contents .checkbox {
	margin-top: 0.25rem;
}
.modal-contents input[type="checkbox"] {
	height: 0.8rem;
}
.modal-contents input:disabled {
	background: $color-3;
	padding-right: 3rem;
	overflow-x: hidden;
	text-overflow: ellipsis;
}
.modal-contents .input .textarea-placeholder {
	height: 16px;
	padding-top: 1.5rem;
	position: absolute;
	left: 1px;
	right: 1rem;
	top: 1px;
	box-sizing: border-box;
	background: $color-1;
	border-radius: 0.25rem;
}
.modal-contents .typeahead-enabled input:disabled ~ .floating-label {
	top: 0.5rem;
	left: 1rem;
	font-size: 11px;
	opacity: 1;
	color: $color-2;
}
.modal-contents input ~ .clear {
	display: none;
}
.modal-contents .side-by-side button {
	margin-top: 2.5rem;
}
.modal-contents input:disabled ~ .clear {
	display: block;
	position: absolute;
	top: 1.25rem;
	font-size: 1rem;
	color: $color-3;
	background: $color-2;
	border-radius: 5rem;
	width: 1.5rem;
	height: 1.5rem;
	text-align: center;
	line-height: 1.75rem;
	right: 1rem;
	cursor: pointer;
}
.modal-contents .has_err input {
	border: 0.1rem solid $color-4;
	background: $color-4-tint;
}
.modal-contents .input.focused input,
.modal-contents input:focus {
	border: 0.2rem solid $color-4;
}
.modal-contents .checkbox input {
	display: inline-block;
	width: auto;
}
.modal-contents textarea:focus ~ .floating-label,
.modal-contents textarea:not(:focus):valid ~ .floating-label,
.modal-contents input:focus ~ .floating-label,
.modal-contents select ~ .floating-label,
.modal-contents input:not(:focus):valid ~ .floating-label {
	top: 0.5rem;
	left: 1rem;
	font-size: 11px;
	opacity: 1;
	color: $color-4;
}

.modal-contents .floating-label {
	position: absolute;
	pointer-events: none;
	left: 1rem;
	top: 1.125rem;
	transition: 0.2s ease all;
}
.modal-contents input:disabled ~ .clear {
	display: none;
}
.modal-contents .typeahead-enabled input:disabled ~ .clear {
	display: block;
}
* */
.modal-contents .datepicker {
	position: absolute;
	top: 0.5rem;
	left: 1rem;
	border: 1px solid $color-2;
	z-index: 10;
	background: $color-1;
	margin-top: 5.25rem;
	box-shadow: 0 3px 9px #00000099;
}
.modal-contents .datepicker .datepicker-header {
	position: relative;
	height: 2rem;
	line-height: 2rem;
	user-select: none;
}
.modal-contents .datepicker .datepicker-next,
.modal-contents .datepicker .datepicker-back {
	position: absolute;
	cursor: pointer;
	top: 0px;
}
.modal-contents .datepicker .datepicker-back {
	left: 0.5rem;
}
.modal-contents .datepicker .datepicker-next {
	right: 0.5rem;
}
.modal-contents .datepicker .datepicker-back:before {
	content: '« ';
}
.modal-contents .datepicker .datepicker-next:after {
	content: ' »';
	right: 0px;
}
.modal-contents .datepicker .disabled.datepicker-next:after {
	content: none;
}
.modal-contents .datepicker .datepicker-month {
	text-align: center;
}
.modal-contents .datepicker .datepicker-calendar {
	display: grid;
	grid-template-columns: 2.5rem 2.5rem 2.5rem 2.5rem 2.5rem 2.5rem 2.5rem;
	grid-gap: 0.2rem;
	padding: 0.2rem;
}
.modal-contents .datepicker .datepicker-date {
	text-align: center;
	background: $color-3;
	line-height: 2.5rem;
	font-size: 0.9rem;
	cursor: pointer;
}
.modal-contents .datepicker .datepicker-date-disabled {
	background: $color-1;
	color: $color-4-tint;
	cursor: default;
}
.modal-contents .datepicker .datepicker-date-selected {
	font-weight: 800;
}
.modal-contents .typeahead {
	position: absolute;
	top: 4.25rem;
	left: 1rem;
	right: 1rem;
	max-height: 20rem;
	border: 1px solid $color-2;
	overflow-y: auto;
	z-index: 10;
	background: $color-1;
	margin-top: 5.25rem;
	box-shadow: 0 3px 9px #00000099;
}
.modal-contents .typeahead.no-suggestions {
	display: none;
}
.modal-contents .typeahead-row {
	padding: 0.5rem 1rem;
	cursor: pointer;
	display: grid;
	grid-template-columns: 40px 1rem auto;
}
.modal-contents .typeahead-row-empty {
	grid-template-columns: 0px 1rem auto;
}
.modal-contents .typeahead-row.selected,
.modal-contents .typeahead-row:hover {
	background: $color-4-tint;
}
.modal-contents .typeahead-row > font,
.modal-contents .typeahead-row img {
	border: 1px solid $color-2;
	grid-column: 1;
}
.modal-contents .typeahead-row > font {
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 30px;
	background: #ccc;
	background: linear-gradient(
		180deg,
		rgba(204, 204, 204, 1) 0%,
		rgba(153, 153, 153, 1) 100%
	);
	color: #333;
	text-align: center;
}
.modal-contents .typeahead-row span {
	grid-column: 3;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.modal-contents .submit {
	text-align: right;
	margin-top: 1rem;
}
.modal-contents .input {
	margin-top: 1rem;
}
/* *
.modal-contents button:disabled {
	opacity: 0.5;
	cursor: default;
}
.modal-contents button:disabled:hover {
	opacity: 0.5;
}
.modal-contents button:hover {
	opacity: 0.95;
}
.modal-contents .err {
	color: $color-4;
	font-weight: 500;
	font-size: 0.9rem;
	margin-top: -0.25rem;
}
.modal-contents .secondary-button {
	margin-right: 1rem;
}
* */
.add-extra-form {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: auto auto 1fr 1rem auto;
	grid-column-gap: 1rem;
	height: 100%;
}
.add-extra-form .add-date {
	grid-row: 1;
	grid-column: 1;
}
.add-extra-form .add-privacy {
	grid-row: 1;
	grid-column: 2;
}
.add-extra-form .add-rating {
	grid-row: 2;
	grid-column: 1/3;
	margin-top: 1rem;
}
.add-extra-form .add-review {
	grid-row: 3;
	grid-column: 1/3;
}
.add-extra-form .add-review,
.add-extra-form .add-review textarea {
	height: 100%;
}
.add-extra-form .add-submit button {
	margin-top: 1rem;
}
.add-extra-form .modal-slider-controls {
	grid-row: 5;
	grid-column: 1/3;
	text-align: right;
}
.select-content-form {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr auto;
	grid-column-gap: 1rem;
	height: 100%;
}
.select-content-form .select-content-primary {
	grid-row: 1;
	grid-column: 1;
}
.select-content-form .select-content-secondary {
	grid-row: 2;
	grid-column: 1;
}
.select-content-form .modal-slider-controls {
	grid-row: 3;
	grid-column: 1;
	text-align: right;
}
.rating-fixed {
	display: none;
}
.rating-hover {
	display: block;
}
/** SKELETONS **/
.item.skeleton,
.skeleton {
	animation: pulse-animation 3s infinite;
	border: 0px;
	background: $color-3-tint;
}
.skeleton.text-100 {
	display: inline-block;
	width: 100%;
	height: 1em;
}
.skeleton.text-50 {
	display: inline-block;
	width: 50%;
	height: 1em;
}
.skeleton.text-10 {
	display: inline-block;
	width: 10rem;
	height: 100%;
}
.skeleton.text-5 {
	display: inline-block;
	width: 5rem;
	height: 100%;
}
.skeleton.text-3 {
	display: inline-block;
	width: 3rem;
	height: 100%;
}
.skeleton.text-1 {
	display: inline-block;
	width: 1rem;
	height: 100%;
}
.main.user .user-stats-type .skeleton.text-1 {
	display: block;
	height: 1.2rem;
	width: 1.2rem;
}
.discover .skeleton.text-3,
.discover .skeleton.text-5,
.main.user .skeleton.text-1,
.main.user .skeleton.text-3,
.main.user .skeleton.text-5,
.paren .skeleton {
	height: 1rem;
}
img.skeleton {
	border: 0px;
}
.content-right > .body > .skeleton:first-child {
	margin-top: 0.5rem;
}
.content-top-book .img.skeleton,
.content-top-tv .img.skeleton,
.content-top-movie .img.skeleton {
	height: 431px;
}
.content-top-podcast .img.skeleton,
.content-top-album .img.skeleton {
	height: 300px;
}
.content-top-tv-episode .img.skeleton,
.content-top-video-game .img.skeleton {
	height: 206px;
}
.main.user > .img > .skeleton {
	width: 300px;
	height: 300px;
	border: 0px;
}
.notification-user .skeleton {
	width: 50px;
	height: 50px;
	border: 0px;
}
.notification-header .skeleton {
	margin-bottom: 0.25rem;
	height: 1.2rem;
}
@keyframes pulse-animation {
	0% {
		background: $color-3-tint;
	}
	50% {
		background: $color-3;
	}
	100% {
		opacity: $color-3-tint;
	}
}

/** BETA **/
.main.beta {
	padding: 0px;
}
.content-row-wrapper {
	padding-top: 1rem;
	padding-left: 1rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid $color-5;
}
.content-row {
	display: grid;
	grid-template-columns: auto auto 1fr;
	width: 500px;
}
.content-row-user-image {
	grid-row: 1/4;
	grid-column: 1;
}
.content-row-user-image img,
.content-row-user-image span {
	border-radius: 4px;
	border: 1px solid $color-5;
	margin-right: 0.75rem;
}
.content-row-user {
	grid-row: 1;
	grid-column: 2;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 0.9rem;
	margin-bottom: 0.5rem;
}
.content-row-user a {
	font-weight: 600;
}
.content-row-user-icon {
	vertical-align: middle;
	margin-right: 0.25rem;
	font-size: 0.85rem;
}
.content-row-review-rating:after {
	content: '⋅';
	margin: 0px 0.25rem;
}
.content-row-time {
	grid-row: 1;
	grid-column: 3;
	text-align: right;
	font-size: 0.9rem;
}
.content-box {
	grid-row: 2;
	grid-column: 2/4;
	display: grid;
}
.content-box-square {
	grid-template-columns: 100px 1fr;
}
.content-box-tall {
	grid-template-columns: 100px 1fr;
}
.content-box-wide {
	grid-template-columns: 1fr;
}
.content-box-wide > img {
	width: 100%;
	height: auto;
}
.content-row-review-rating {
	font-weight: 600;
}
.content-box-body {
	padding: 1rem;
}
.context-box-title > a {
	font-weight: 600;
	margin-bottom: 0.25rem;
	display: block;
}
.context-box-subtitle {
	font-style: italic;
	font-size: 0.9rem;
}
.content-box-review {
	margin-top: 0.5rem;
	font-size: 0.9rem;
	grid-row: 3;
	grid-column: 2/4;
}
/* * MOBILE * */
@media only screen and (max-width: 1050px) {
	#root > div {
		display: grid;
		grid-template-columns: 6rem 1fr;
		grid-template-rows: auto 1fr;
		height: 100%;
	}
	.left-nav > div > span {
		display: none;
	}
	.left-nav > div {
		text-align: center;
		margin-left: 0px;
	}
	.section-nav {
		left: 6rem;
	}
	.discover-group-grid,
	.content .similar-grid {
		grid-template-columns: 1fr 1fr;
		grid-row-gap: 1rem;
	}
	.discover .pagination {
		margin: 0 -1rem;
		padding: 1rem 0px;
	}
	.discover-group-grid-header {
		grid-column: 1/3;
	}
}
@media only screen and (max-width: 767px) {
	.discover {
		grid-template-columns: 1fr;
	}
	.left-nav {
		width: 100%;
		margin-left: -110%;
		position: fixed;
		top: 0rem;
		bottom: 0px;
		background: $color-1;
		transition: margin 0.5s ease;
		margin-top: 0rem;
		padding-top: 2rem;
		border-right: 0px;
	}
	.left-nav > div > span {
		display: inline;
	}
	.left-nav > a {
		display: none;
	}
	.left-nav.displayed {
		margin-left: 0px;
	}
	.left-nav-control.gone {
		display: grid !important;
		position: fixed;
		z-index: 1002;
		top: 0.2rem;
		left: 0.5rem;
	}
	.left-nav-control > div {
		position: relative;
		width: 1.6rem;
		height: 1.6rem;
		margin: 0.2rem 0rem 0.2rem 0.25rem;
	}
	.left-nav-control > div > div:nth-child(1) {
		width: 0.7rem;
		height: 1.6rem;
		top: 0px;
		left: 0px;
		border-radius: 4px 0px 0px 4px;
	}
	.left-nav-control > div > div:nth-child(2) {
		width: 0.7rem;
		height: 0.7rem;
		top: 0px;
		right: 0px;
		border-radius: 0px 4px 0px 0px;
	}
	.left-nav-control > div > div:nth-child(3) {
		width: 0.7rem;
		height: 0.7rem;
		bottom: 0px;
		right: 0px;
		border-radius: 0px 0px 4px 0px;
	}
	.left-nav-control > div > div {
		position: absolute;
		background: $color-2;
		transition: width 0.25s, transform 0.25s, left 0.25s, top 0.25s;
	}
	.left-nav-control.displayed > div > div {
		width: 0.2rem;
	}
	.left-nav-control.displayed > div > div:nth-child(1) {
		left: 0.75rem;
		transform: rotate(45deg);
	}
	.left-nav-control.displayed > div > div:nth-child(2),
	.left-nav-control.displayed > div > div:nth-child(3) {
		transform: rotate(-45deg);
		left: 0.75rem;
		height: 1.6rem;
	}
	.left-nav > div {
		text-align: left;
	}
	.left-nav-control .nav-alert {
		left: 1.4rem;
		top: -0.2rem;
		transition: opacity 0.25s;
	}
	.left-nav-control.displayed .nav-alert {
		opacity: 0;
	}
	#root > div {
		grid-template-columns: 0px 1fr;
	}
	.item-body {
		height: 260px;
	}
	.item-body-bg span {
		line-height: 260px;
	}
	.user-content > .smalls,
	.user-content > .box-wrapper,
	.home > .smalls,
	.home > .box-wrapper {
		width: 100%;
	}
	.content .content-top {
		grid-template-columns: 1fr;
		grid-column-gap: 0;
		grid-row-gap: 1rem;
	}
	.content .content-top .content-paren {
		grid-row: 1;
		grid-column: 1;
		margin-bottom: 0px;
	}
	.content .content-top .content-left {
		grid-row: 2;
		grid-column: 1;
	}
	.content .content-top .content-right {
		grid-row: 3;
		grid-column: 1;
	}
	.content .content-attribution {
		grid-row: 4;
		grid-column: 1;
	}
	.main.user .img img,
	.content .content-top .img img {
		width: 100%;
		height: 100%;
	}
	.content .content-bottom {
		grid-template-columns: 1fr;
	}
	.content .content-bottom > div:last-child {
		grid-column: 1;
		margin-top: 1rem;
		grid-row: 2;
	}
	.content .similar-grid {
		grid-template-columns: 1fr 1fr;
		grid-row-gap: 1rem;
	}
	.item {
		height: 260px;
		grid-template-columns: 60px minmax(0, 1fr);
	}
	.item .viewer {
		grid-row: 2/4;
	}
	.item-info-bottom {
		grid-column: 1/3;
		padding-left: 10px;
	}
	.item .viewer img {
		width: 40px;
		height: auto;
	}
	.item .viewer span {
		width: 40px;
		height: 40px;
		line-height: 40px;
		font-size: 25px;
	}
	.section-nav {
		padding-left: 3rem;
	}
	.main.user {
		grid-template-columns: 1fr;
	}
	.main.home .user-data {
		display: block;
	}
	.main.home .user-data .user-image img,
	.main.user .user-data .user-image img {
		width: 100%;
		height: auto;
	}
	.main.home
		.user-data
		.user-image
		img.skeleton
		.main.user
		.user-data
		.user-image
		img.skeleton {
		height: 250px;
	}
	.main.home .user-data .viewer span,
	.main.user .user-data .viewer span {
		width: 100%;
		font-size: 140px;
	}
	.main.home .box-wrapper > .content,
	.main.user .box-wrapper > .content {
		margin-right: 0px;
	}
	.splash {
		grid-template-columns: 1fr;
		grid-template-rows: auto auto;
	}
	.splash a.buttony {
		display: block;
		text-align: center;
		margin-bottom: 1rem;
	}
	.splash-content {
		grid-column: 1;
		grid-row: 1;
	}
	.modal-box .MuiDialog-container > div {
		width: 100%;
		max-height: none;
		max-width: none;
		box-shadow: none;
		transition: none;
		margin: 0px;
		height: 100%;
		top: auto;
		left: auto;
		transform: none;
		border-radius: 0px;
		background: $color-1;
	}
	.modal-contents {
		padding: 0px 1rem;
	}
	.action .inline-likes {
		display: block;
	}
	.modal-contents .notice > a {
		display: block;
	}
	.modal-dialog {
		width: 100%;
		margin: 0px;
		height: 100%;
	}
	.modal-content {
		border-radius: 0px;
		height: 100%;
		border: 0px;
	}
	.modal-close {
		right: 1rem;
		top: 1rem;
	}
	.modal-title {
		top: 0;
		left: 0;
		right: 0;
		margin: 1rem 2.5rem 1rem 1rem !important;
	}

	.modal-contents.fake {
		position: inherit;
	}
	.modal-contents {
		position: fixed;
		top: 4rem;
		left: 0;
		right: 0;
		bottom: 0;
		overflow-y: auto;
	}
	.modal-contents .forgot {
		float: left;
	}
	.splash > div.quilt {
		display: flex;
		grid-column: 1;
		grid-row: 2;
		padding-top: 1rem;
		//		grid-template-columns: 1fr 1fr 1fr;
		height: 25rem;
	}
	.splash > div.quilt > .quilt_column {
		width: 20%;
		display: flex;
		flex-direction: column;
	}
	.splash > div.quilt > div {
		display: none;
	}
	.add-button.gone {
		display: block !important;
	}
	.add-button {
		position: fixed;
		right: 1rem;
		bottom: 1rem;
		z-index: 1000;
		font-size: 3rem;
		background: $color-4;
		border-radius: 10rem;
		width: 4rem;
		height: 4rem;
		text-align: center;
		line-height: 5rem;
		cursor: pointer;
		box-shadow: 2px 2px 5px $color-2;
	}
	.add-button a {
		color: $color-1;
	}
	.modal-content .add-rating > div > div > div:last-child span {
		font-size: 1.4rem;
	}
	.modal-content .rating .full-stars,
	.modal-content .rating .empty-stars {
		font-size: 1.5rem;
	}
	.modal-content .rating .rating-value {
		display: none;
	}
	.modal-contents .typeahead {
		margin-top: 1rem;
	}
	.item .item-image img {
		position: absolute;
		left: 50%;
		transform: translatex(-50%);
	}
	.home.bookmarks .item-image img {
		width: 100%;
		height: auto;
	}
	.home.bookmarks > .smalls,
	.home.bookmarks > .box-wrapper {
		width: 100%;
	}
	.home.bookmarks .smalls .item,
	.home.bookmarks .item {
		height: 173px;
		grid-template-columns: 0px minmax(0, 1fr);
	}
	.home.bookmarks .item .item-info-bottom,
	.home.bookmarks .item .title-subtitle {
		padding-left: 10px;
	}
	.no-mobile-controls .controls {
		display: none;
	}
	.main .modal-contents {
		position: initial;
		overflow-y: initial;
	}
	.discover-group-body-carousel {
		left: 1px;
		right: 1px;
	}
	.discover-section-header {
		grid-template-columns: 1fr;
		grid-template-rows: auto auto;
		border-bottom: 0px;
	}
	.discover-section-header-search {
		justify-self: auto;
	}
	.search-box input {
		width: auto;
	}
	.side-by-side {
		height: 100%;
		vertical-align: top;
	}
	.modal-slider-wrapper {
		height: 100%;
	}
	.modal-slider {
		height: 100%;
	}
	.modal-contents .datepicker {
		margin-top: 1.5rem;
	}
	.user-content-stats {
		grid-template-columns: 1fr 1fr;
		grid-row-gap: 0.5rem;
		grid-template-rows: auto auto;
	}
	.user-content-stats-button {
		margin: 1rem 0px !important;
	}
	.user-data .member-since {
		margin-bottom: 0.5rem;
	}
	.user-stats {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
	}

	.rating-fixed {
		display: block;
	}
	.rating-hover {
		display: none;
	}
	.edit-list .list-add-wrapper {
		grid-template-columns: 1fr;
	}
	.edit-list .list-add-wrapper .list-add-parent ~ .list-add-content,
	.edit-list .list-add-wrapper .list-note,
	.edit-list .list-add-wrapper .list-add,
	.edit-list .list-add-wrapper .list-add-content,
	.edit-list .list-add-wrapper .list-add-parent {
		grid-column: 1;
		grid-row: auto;
	}
	.list-item {
		display: block;
		grid-column-gap: 1rem;
		border-bottom: 1px solid $color-5;
		margin-bottom: 1rem;
		padding-bottom: 1rem;
	}
	.list-item-images {
		grid-column: 1;
		grid-row: 1;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		grid-column-gap: 2px;
		grid-row-gap: 2px;
		width: 100%;
		height: auto;
	}
	.list-item-images img {
		width: 100%;
		height: auto;
	}
	.list-item-title {
		margin-top: 1rem;
		grid-column: 1;
		grid-row: 2;
	}
	.list-item-creator {
		grid-column: 1;
		grid-row: 3;

		grid-template-columns: auto 1fr;
	}
	.list-item-creator .list-item-creator-date {
		grid-column: 1/3;
	}
	.list-item-creator .list-item-creator-date:before {
		content: none;
	}
	.list-item-description {
		grid-column: 1;
		grid-row: 4;
	}
	.edit-list .list-content-item .content-item-editable {
		grid-template-columns: auto 1fr 1fr;
		grid-template-rows: auto auto auto 1fr auto;
	}
	.edit-list .content-item-editable .content-item-image {
		grid-column: 1;
		grid-row: 1/5;
	}
	.edit-list .content-item-editable .content-item-edit {
		grid-column: 2;
		grid-row: 1;
		text-align: center;
	}
	.edit-list .content-item-editable .content-item-delete {
		grid-column: 3;
		grid-row: 1;
		text-align: center;
	}
	.edit-list .content-item-editable .content-item-name {
		grid-column: 2/4;
		grid-row: 2;
	}
	.edit-list .content-item-editable .content-item-parent {
		grid-column: 2/4;
		grid-row: 3;
	}
	.edit-list .content-item-editable .content-item-parenthetical {
		grid-column: 2/4;
		grid-row: 4;
	}
	.edit-list .content-item-note {
		grid-column: 1/4;
		grid-row: 5;
	}
	.consumption-reply {
		grid-template-columns: auto 1fr;
		grid-template-rows: auto;
		grid-column-gap: 1rem;
	}
	.consumption-reply .reply-user {
		grid-row: 1/5;
		grid-column: 1;
	}
	.consumption-reply .reply-name {
		grid-column: 2;
		grid-row: 1;
	}
	.consumption-reply .reply-date {
		grid-column: 2;
		grid-row: 2;
		margin-left: 0px;
	}
	.consumption-reply .reply-controls {
		grid-column: 2;
		grid-row: 4;
		text-align: left;
		margin-left: 0px;
	}
	.consumption-reply .reply-body {
		grid-column: 2;
		grid-row: 3;
		margin-top: 0.25rem;
	}
}
@media only screen and (max-width: 400px) {
	.consumption-user-date-separator {
		display: none;
	}
	.consumption-date {
		display: block;
	}
}
